import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Thanks = () => (
    <Layout showCookieConsent={false}>
        <SEO title="Thanks for your message" />
        <div className={"container"}>

            <div className={"content"}>
                <div className={"title"}>
                    <h1>Thanks for contacting us</h1>
                </div>
            </div>
            <div><p>Thanks for your message. We may need some time to process and react to your message. So don't be upset, if it takes a little longer in times we receive many messages.</p></div>
        </div>
    </Layout>
)

export default Thanks